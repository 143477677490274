<template>
	<div class="main-contents">
		<div class="tit">공급기업 담당자 승인관리</div>
		<div class="search-box interval">
			<SelectComp list = "01:승인,02:미승인" :isAll="true" title="담당자 승인여부" v-model="srchFilter.apprDiv"/>
			<nbsp/>
			<SelectComp list="corp:공급기업명,mber:담당자명" v-model="srchFilter.srchTxtDiv" @change="srchTxtPlaceholder = $event.target.selectedOptions[0].label"/>
			<InputComp v-model="srchFilter.srchTxt" :placeholder="srchTxtPlaceholder" @keyup.enter="getSupplyCorpInfoList('1')" />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getSupplyCorpInfoList('1')" />
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="5%">
					<col width="10%">
					<col width="10%">
					<col width="26%">
					<col width="26%">
					<col width="13%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>공급기업 담당</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>공급기업명</th>
						<th>회원가입일</th>
						<th>담당자 승인</th>
					</tr>
				</thead>
				<tbody v-if="supGmgList.length > 0">
					<tr v-for="(row, rowIdx) in supGmgList" :key="rowIdx" :class="row.mberStatusCd == '02' ? 'new' : ''">
						<!-- <td class="score"><div class="new" v-if="row.mberStatusCd == '02'">new</div>{{rowIdx + 1}}</td> -->
						<td class="score"><div class="new" v-if="row.mberStatusCd == '02'">new</div>{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
						<td class="score name" @click="openPopup(row.mberSeq)">{{row.mberNm}} &#9654;</td>
						<td class="score">{{row.mberSeq}}</td>
						<td class="score">{{row.loginId}}</td>
						<td class="score">{{row.corpNm}}</td>
						<td class="score">{{row.regYmd | date('yyyy.MM.dd')}}</td>
						<td class="score resume" :class="row.mberStatusCd == '01' ? 'apvl' : 'resume'">
							<div v-if="row.mberStatusCd == '01'">승인</div>
							<div class="btn rq" v-else @click="scoAppr(row.mberSeq, rowIdx)">승인하기 &#9654;</div>
						</td>

						<!-- <td class="score" :class="row.apprDivCd == 'Y' ? 'apvl' : 'resume'">
							<div v-if="row.apprDivCd == 'Y'">승인</div>
							<div class="btn rq" v-else-if="row.apprDivCd == 'N'" @click="scoAppr(row.mberSeq, row.corpNm)">승인하기 &#9654;</div>
							<div class="btn rq" v-else-if="row.apprDivCd == 'R'" @click="scoAppr(row.mberSeq, row.corpNm)">재승인하기 &#9654;</div>
						</td> -->
					</tr>
				</tbody>
				<tbody v-else>
					<!-- 관련내용 없을 경우 -->
					<tr>
						<td colspan="9" class="none">등록된 공급기업 담당 회원이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			srchFilter : {
				apprDiv : '',
				srchTxtDiv : 'corp',
				pageIndex : 1,
			},

			supGmgList : [],

			pageInfo : {},

			srchTxtPlaceholder : '공급기업명'
		}
	},

	components : {
		pagingComp
	},
	beforeMount() {
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}
		this.getSupplyCorpInfoList();
	},

	methods : {
		getSupplyCorpInfoList(div) {
			var param = this.srchFilter
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			this.$.httpPost('/api/mem/adm/sco/selectScoGmgInfoList', param)
				.then(res => {
					//console.log('selectScoGmgInfoList RESULT', res);

					this.supGmgList = res.data.supGmgList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		openPopup(seq) {
			var param = {};
			param.div = 'sco';
			param.reqMberSeq = seq;

			this.$.popup('/adm/mem/MEM923P02', param);
			
		},

		scoAppr(seq, idx) {
			var obj =  this.supGmgList[idx];

			this.$.popup('/adm/mem/MEM930P01', {mberNm:obj.mberNm, loginId:obj.loginId, corpNm:obj.corpNm})
				.then(res => {
					if(res) {
						this.$.httpPost('/api/mem/adm/sco/admScoMberAppr', {scoMberSeq : seq})
							.then(() => {
								this.getSupplyCorpInfoList();
							}).catch(this.$.httpErrorCommon);
					}
				})
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getSupplyCorpInfoList();
		},
	}
}
</script>